function BookingProcessorModule(bookingModule) {
    var self = this;

    self.bookingModule = bookingModule ? bookingModule : new BookingModule();

    self.process = function (data, target) {
        if (Array.isArray(data)) {
            if (!target) {
                target = [];
            }
            ko.utils.arrayForEach(data, function (item) {
                target.push(self.addInformation(new BookingModel(item)));
            });
            return target;
        }

        return self.addInformation(new BookingModel(data));
    };

    self.addInformation = function (item) {
        item.loading = ko.observable(false);

        item.setAccepted = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'If you want, you can send a message to the customer',
                input: 'textarea',
                inputPlaceholder: 'Write the message',
                inputValidator: function (value) {
                    if (!value || value.length < 5)
                        return 'You have to write a motivation of at least 5 characters!'
                },
                inputAttributes: {
                    minlength: 5
                },
                showCancelButton: true,
                cancelButtonColor: '#cccccc',
                confirmButtonText: 'Ok, send',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-light-gray',
                },
                buttonsStyling: false
            }).then(function (result) {
                item.loading(true);
                self.bookingModule.accepted(item.id(), result.value)
                    .then(function (response) {
                        if (response.success) {
                            item.state('accepted');
                            item.status(response.data.booking.status);
                        }
                        self.bookingModule.notifyResponse(response);
                        item.loading(false);
                    })
                    .catch(function () {
                        item.loading(false);
                    })
            })
        };

        item.setDenied = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Are you sure you want to refuse it?',
                input: 'textarea',
                inputPlaceholder: 'Write the motivation',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'You have to write a motivation of at least 10 characters!'
                },
                inputAttributes: {
                    minlength: 5
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, reject it.',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.denied(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('denied');
                                item.status(response.data.booking.status);
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setCanceled = function () {
            if (item.loading()) {
                return;
            }
            if (!item.isVoidable()) {
                var html = 'As per the terms of use, you can no longer cancel the request online. ';
                if (item.isAfterDate()) {
                    html += 'since the time allocated for providing the service has passed. ';
                } else if (item.service_type()==='sentHome') {
                    html += 'It has already been accepted and being in execution you can no longer cancel it online '
                } else {
                    html += 'There are less than 30 minutes to the time established for the provision of the service.';
                }
                html += 'Call the business ' + item.catering.name + ' immediately at their number ' +
                  '<a href="tel:' + item.catering.phoneHome + '">' + item.catering.phoneHome + '</a> and communicate' +
                  ' your needs verbally, possibly arranging a new solution.<br>' +
                  'For any support, you can write to us at support@findgusto.co.uk';
                Swal.fire({
                    title: 'Warning: you can no longer cancel the Request online',
                    html: html,
                    icon: 'warning',
                    width: 800,
                    confirmButtonText: 'OK! I got it',
                    customClass: {
                        confirmButton: 'btn btn-success mg-r-xs',
                    },
                    buttonsStyling: false
                });
                return;
            }

            Swal.fire({
                title: 'Are you sure you want to delete it?',
                input: 'textarea',
                inputPlaceholder: 'Write the motivation',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'You have to write a motivation of at least 10 characters!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, cancel',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.canceled(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('canceled');
                                item.status(response.data.booking.status);
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setProposed = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Propose the modification to the Customer',
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, send',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.proposed(item.id())
                        .then(function (response) {
                            if (response.success) {
                                item.state('proposed')
                                item.proposal().state('requested')
                                item.details(response.data.booking.details)
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        }).catch(function () {
                        item.loading(false);
                    })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setProposalAnnulled = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Do you want to proceed with the cancellation?',
                html: 'Warning: you will not be able to send a new Counterproposal.<br> ' +
                    'By canceling the Counterproposal you have already sent, you are also permanently rejecting ' +
                    'and losing the initial Customer Request.',
                input: 'textarea',
                inputPlaceholder: 'Enter the reason',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'You must provide a reason with at least 10 characters!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, proceed',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.proposalAnnulled(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('denied')
                                item.proposal().state('annulled')
                                item.proposal().state_note(result.value)
                                item.details(response.data.booking.details)
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setProposalDenied = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Are you sure you want to reject the counterproposal?',
                input: 'textarea',
                inputPlaceholder: 'Enter the reason',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'You must provide a reason with at least 10 characters!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, reject',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.proposalDenied(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('denied')
                                item.proposal().state('denied')
                                item.proposal().state_note(result.value)
                                item.details(response.data.booking.details)
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setProposalAccepted = function () {
            if (item.loading()) {
                return;
            }

            function setAccepted() {
                item.loading(true);
                self.bookingModule.proposalAccepted(item.id())
                    .then(function (response) {
                        if (response.success) {
                            item.state('accepted')
                            item.proposal().state('accepted')

                        }
                        self.bookingModule.notifyResponse(response);
                        item.loading(false);
                    })
                    .catch(function () {
                        item.loading(false);
                    })
            }

            if (item.price() !== item.proposal().total()) {
                Swal.fire({
                    title: 'Do you accept the Counterproposal?',
                    html: 'The Business in its Counterproposal has changed the total amount to be paid.<br><br>\n' +
                      'By accepting the Counterproposal, you authorize the new amount:',
                    showCancelButton: true,
                    cancelButtonColor: '#CC1100',
                    confirmButtonText: 'Yes, I accept',
                    cancelButtonText: 'No',
                    customClass: {
                        confirmButton: 'btn btn-success mg-r-xs',
                        cancelButton: 'btn btn-danger',
                    },
                    buttonsStyling: false
                }).then(function (result) {
                    if (result.value) {
                        setAccepted();
                    } else {
                        item.loading(false);
                    }
                })
            } else {
                setAccepted();
            }

        };

        item.setClaimed = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Is the Request complete?',
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, complete',
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.claimed(item.id())
                        .then(function (response) {
                            if (response.success) {
                                item.state('claimed')
                            }
                            self.bookingModule.notifyResponse(response)
                            item.loading(false)
                        }).catch(function () {
                        item.loading(false)
                    })
                } else {
                    item.loading(false)
                }
            })
        };

        item.setDeclaredSeats = function () {
            if (item.loading()) {
                return
            }
            item.loading(true)
            Swal.fire({
                title: 'If there have been changes, specify the actual number of people served',
                input: 'text',
                inputValue: item.declared_seats() ? item.declared_seats() : item.seats(),
                inputPlaceholder: 'Number of people:',
                inputValidator: function (value) {
                    if (!value || value <= 0)
                        return 'The minimum number of people is 1!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, modify',
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true)
                    self.bookingModule.declareSeats(item.id(), result.value)
                      .then(function (response) {
                          if (response.success) {
                              item.declared_seats(result.value)
                          }
                          self.bookingModule.notifyResponse(response)
                          item.loading(false)
                      }).catch(function () {
                        item.loading(false)
                    })
                } else {
                    item.loading(false)
                }

            })
        }

        //@TODO sostituire con tabella booking processing con gli stati di lavorazione
        item.setSent = function () {
            if (item.loading()) {
                return
            }
            Swal.fire({
                title: 'Has the Order left your location?',
                html: 'If you confirm that the request from the Customer has been dispatched, we will inform them with a notification and via email.',
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, I confirm',
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.claimed(item.id())
                        .then(function (response) {
                            if (response.success) {
                                item.state('claimed');
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        }).catch(function () {
                        item.loading(false);
                    })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setPayed = function () {
            if (item.loading()) {
                return;
            }
            Swal.fire({
                title: 'Do you confirm that the customer has paid for the service?',
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, I confirm',
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.confirmPayment(item.id())
                      .then(function (response) {
                          if (response.data.success) {
                              item.transaction().state('payed');
                          }
                          item.loading(false);
                      }).catch(function () {
                        item.loading(false);
                    })
                } else {
                    item.loading(false);
                }
            })
        };

        item.setMissed = function () {
            if (item.loading()) {
                return;
            }
            item.loading(true);
            Swal.fire({
                title: 'Do you confirm that you want to declare the client as a "No Show," meaning their "absence" at the agreed time/place for the requested service?',
                input: 'textarea',
                inputPlaceholder: 'Describe what happened; we will also ask the Customer for details',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'You must write a text of at least 10 characters!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, I confirm',
                cancelButtonText: 'Cancel',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    self.bookingModule.missed(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('missed');
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }

            })
        };

        item.setAnnulled = function () {
            if (item.loading()) {
                return;
            }

            if (!item.isVoidable()) {
                var html = 'As per the terms of use, you can no longer cancel the Request online,  '
                if (item.isAfterDate()) {
                    html += 'since the scheduled time for providing the service has passed. '
                } else if (item.service_type() === 'sentHome') {
                    html += 'it has already been accepted and is in progress, so you can no longer cancel it online. '
                } else {
                    html += 'with less than 30 minutes remaining before the scheduled time for providing the service. ';
                }
                html += 'Call the Customer immediately at their number ' +
                  '<a href="tel:' + item.phone() + '">' + item.phone() + '</a> and inform them' +
                  ' of the cancellation verbally, possibly arranging a new solution.<br>' +
                  'For further assistance, you can write to us at support@findgusto.co.uk'
                Swal.fire({
                    title: 'Warning: you can no longer cancel the Request online',
                    html: html,
                    width: 800,
                    icon: 'warning',
                    confirmButtonText: 'Ok, I understand',
                    customClass: {
                        confirmButton: 'btn btn-success mg-r-xs',
                    },
                    buttonsStyling: false
                });
                return;
            }

            Swal.fire({
                title: 'Are you sure you want to Cancel?',
                input: 'textarea',
                inputPlaceholder: 'Enter the reason',
                inputValidator: function (value) {
                    if (!value || value.length < 10)
                        return 'You must provide a reason with at least 10 characters!'
                },
                showCancelButton: true,
                cancelButtonColor: '#CC1100',
                confirmButtonText: 'Yes, cancel',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                    cancelButton: 'btn btn-danger',
                },
                buttonsStyling: false
            }).then(function (result) {
                if (result.value) {
                    item.loading(true);
                    self.bookingModule.annulled(item.id(), result.value)
                        .then(function (response) {
                            if (response.success) {
                                item.state('annulled');
                                item.status(response.data.booking.status);
                            }
                            self.bookingModule.notifyResponse(response);
                            item.loading(false);
                        })
                        .catch(function () {
                            item.loading(false);
                        })
                } else {
                    item.loading(false);
                }
            })
        };

        item.toggleArchivedCatering = function () {
            if (item.loading()) {
                return;
            }
            item.loading(true);
            self.bookingModule.archived(item.id(), 'catering', !item.catering_archived())
                .then(function (response) {
                    if (response.success) {
                        item.catering_archived(response.data.catering_archived);
                    }
                    self.bookingModule.notifyResponse(response);
                    item.loading(false);
                })
                .catch(function () {
                    item.loading(false);
                })
        };

        item.toggleArchivedUser = function () {
            if (item.loading()) {
                return;
            }
            item.loading(true);
            self.bookingModule.archived(item.id(), 'user', !item.user_archived())
                .then(function (response) {
                    if (response.success) {
                        item.user_archived(response.data.user_archived);
                    }
                    self.bookingModule.notifyResponse(response);
                    item.loading(false);
                }).catch(function () {
                item.loading(false);
            })
        };

        item.isAfterDate = function () {
            if (!item.service_datetime())
                return false;
            var now = moment();
            var eventDate = moment(item.service_datetime());
            return now.isAfter(eventDate);
        }

        item.isVoidable = function () {
            if (item.state() !== 'accepted') {
                return true;
            }
            if (item.transaction().state === 'payed') {
                return false;
            }
            if (item.service_datetime()) {
                var now = moment().add(30, 'minutes');
                var eventDate = moment(item.service_datetime());
                return now.isSameOrBefore(eventDate);
            }
            if (item.service_type()==='sentHome' && item.state() === 'accepted') {
                return false;
            }
            return true
        }

        item.isTerminated = ko.computed(function () {
            if (item.state() === 'expired')
                return true
            if (item.state() !== 'requested')
                return false
            var isAfter = item.isAfterDate()
            if (isAfter)
                item.state('expired')
            return !!isAfter
        })

        item.getSeats = ko.computed(function () {
            if (item.declared_seats() && item.declared_seats() !== item.seats())
                return item.declared_seats() + ' <span title="Initial request: ' + item.seats() + '">(<del>' + item.seats() + '</del>)</span>'
            return item.seats()
        })

        item.getSeatsProposal = ko.computed(function () {
            if (!item.proposal())
                return
            if (item.declared_seats() && item.declared_seats() !== item.proposal().seats())
                return item.declared_seats() + ' <span title="Counterproposal: ' + item.proposal().seats() + '">(<del>' + item.proposal().seats() + '</del>)</span>'
            return item.proposal().seats()
        })

        item.isArchivableUser = ko.computed(function () {
            var stateArray = ['canceled', 'annulled', 'denied', 'expired', 'claimed', 'missed']
            if (stateArray.includes(item.state()))
                return true
            if (item.state() === 'accepted') {
                return item.isAfterDate()
            }
            return false
        })

        item.isArchivableCatering = ko.computed(function () {

            var stateArray = ['canceled', 'annulled', 'denied', 'expired', 'claimed', 'missed'];
            return stateArray.includes(item.state());

        });

        item.stateLabel = ko.computed(function () {
            switch (item.state()) {
                case 'requested':
                    return "<span class=\"label label-danger blinking-button\">Pending</span>";
                case 'accepted':
                    return "<span class=\"label label-success blinking-button\">Accepted</span>";
                case 'canceled':
                    return "<span class=\"label label-warning\">Cancelled</span>";
                case 'annulled':
                    return "<span class=\"label label-warning\">Annulled</span>";
                case 'denied':
                    return "<span class=\"label label-warning\">Rejected</span>";
                case 'missed':
                    return "<span class=\"label black-border text-primary\">No show</span>";
                case 'expired':
                    return "<span class=\"label label-black\" " +
                        "data-toggle=\"tooltip\" " +
                        "title=\"The Request has expired because the Busiiness did not respond in time\">" +
                        "Expired" +
                        "</span>";
                case 'proposed':
                    return "<span class=\"label label-danger blinking-button\">Counterproposal</span>";
                case 'reviewed':
                    return "<span class=\"label label-grey\">Review</span>";
                case 'shared':
                    return "<span class=\"label label-grey\">Shared</span>";
                case 'claimed':
                    return "<span class=\"label success-border text-primary\">Completed</span>";
                case 'drafted':
                    return "<span class=\"label label-grey\">Draft</span>";
                case 'pending':
                    return "<span class=\"label label-grey\">Pending</span>";
                default:
                    return item.state();
            }

        });

        item.stateTextLabel = ko.computed(function () {
            switch (item.state()) {
                case'requested':
                    return "<span class=\"label label-danger blinking-button\">Status: Pending</span>";
                case'accepted':
                    return "<span class=\"label label-success\">Status: Accepted</span>";
                case'canceled':
                    return "<span class=\"label label-warning\">Status: Cancelled</span>";
                case'annulled':
                    return "<span class=\"label label-warning\">Status: Annulled</span>";
                case'denied':
                    return "<span class=\"label label-warning\">Status: Rejected</span>";
                case'missed':
                    return "<span class=\"label label-black\">Status: No show</span>";
                case'expired':
                    return "<span class=\"label label-black\" " +
                        "data-toggle=\"tooltip\" " +
                        "title=\"The Request has expired because the Business did not respond in time\">Status: " +
                        "Expired" +
                        "</span>";
                case'proposed':
                    return "<span class=\"label label-danger blinking-button\">Status: Counterproposal</span>";
                case'reviewed':
                    return "<span class=\"label label-grey\">Status: Review</span>";
                case'shared':
                    return "<span class=\"label label-grey\">Status: Shared</span>";
                case'claimed':
                    return "<span class=\"label label-success\">Status: Completed</span>";
                case'drafted':
                    return "<span class=\"label label-grey\">Status: Draft</span>";
                default:
                    return item.state();
            }

        });

        item.serviceDate = ko.computed(function () {
            if (item.proposal() && item.proposal() !== 'drafted' && item.proposal().service_date())
                return item.proposal().service_date()
            return item.service_date()
        });

        item.serviceTime = ko.computed(function () {
            if (item.proposal() && item.proposal() !== 'drafted' && item.proposal().service_time())
                return item.proposal().service_time()
            return item.service_time()
        });

        item.tooltipBookingCode = ko.computed(function () {
            if (item.booking_code().indexOf('PTA') !== -1) {
                return "Table Reservation"
            }
            if (item.booking_code().indexOf('PTO') !== -1) {
                return "Table Reservation + Preorder"
            }
            if (item.booking_code().indexOf('ODE') !== -1) {
                return "Delivery Order"
            }
            if (item.booking_code().indexOf('OTA') !== -1) {
                return "Takeaway Reservation"
            }
            if (item.booking_code().indexOf('PPH') !== -1) {
                return "Chef/Catering Reservation"
            }
            if (item.booking_code().indexOf('PPO') !== -1) {
                return "Chef/Catering Reservation + Preorder"
            }
            if (item.booking_code().indexOf('OST') !== -1) {
                return 'Order with Shipping'
            }
            return item.booking_code();
        });

        item.serviceLabel = ko.computed(function () {
            switch (item.service_type()) {
                case 'delivery':
                    return "<span class=\"label label-grey\">Delivery</span>";
                case 'takeaway':
                    return "<span class=\"label label-grey\">Takeaway / Purchase</span>";
                case 'preparedHome':
                    return "<span class=\"label label-grey\">Chef / Catering</span>";
                case 'sentHome':
                    return "<span class=\"label label-grey\">Shipping</span>";
                case 'table':
                    return "<span class=\"label label-grey\">Venue</span>";
                default:
                    return item.service_type();
            }

        });

        item.tagLabel = ko.computed(function () {
            var tag = '';
            if (item.service_type() === 'table' || item.service_type() === 'preparedHome') {
                if (item.items && item.items().length > 0)
                  tag += '<span class="label label-grey" ' +
                    'data-toggle="tooltip" ' +
                    'title="In this Order/Reservation, ' + item.items().length + ' products have been added">' +
                    'Preorder' +
                    '</span>'
            }
            return tag
        });

        item.priceTotalDiscount = ko.computed(function () {
            var discount = item.subtotal() - item.price()
            return discount > 0 ? -discount.toFixed(2) : 0
        })

        item.priceDiscountPromotion = ko.computed(function () {
            var discount = item.discountPromotion()
            return discount > 0 ? -discount.toFixed(2) : 0
        })

        item.priceExtraDiscount = ko.computed(function () {
            var discount = item.extra_discount_price()
            return discount > 0 ? -discount.toFixed(2) : 0
        })

        item.priceDiscountCoupon = ko.computed(function () {
            var discount = item.discountCoupon()
            return discount > 0 ? -discount.toFixed(2) : 0
        })

        item.addressLabel = ko.computed(function () {
            if (item.service_type() === 'delivery') {
                return 'Delivery Address'
            }

            if (item.service_type() === 'sentHome') {
                return 'Shipping Address'
            }

            if (item.service_type() === 'preparedHome') {
                return 'Service Address';
            }

            return 'Address';
        });

        item.servicePriceLabel = ko.computed(function () {
            if (item.service_type() === 'delivery') {
                return 'Delivery';
            }

            if (item.service_type() === 'sentHome') {
                return 'Shipping';
            }

            if (item.service_type() === 'preparedHome') {
                return 'Service';
            }

            return 'Service';
        });

        item.paypalInfo = function () {
            Swal.fire({
                title: 'PayPal Information',
                html: 'FindGusto collects payments online through the PayPal system, in the name and on behalf of your Business, ' +
                    'for amounts due from your Customer. Every two weeks, if the total credit amount exceeds £200,' +
                    ' otherwise monthly, we will transfer the collected amounts to you, minus the reservation/ordering and ' +
                    'electronic payment fees.',
                icon: 'info',
                width: 800,
                confirmButtonText: 'Ok, I understand',
                customClass: {
                    confirmButton: 'btn btn-success mg-r-xs',
                },
                buttonsStyling: false
            })
        }

        item.paymentLabel = ko.computed(function () {
            if (!item.transaction())
                return 'Not specified';

            if (item.transaction().isOnlinePayed)
                return 'already paid online with PayPal';

            if (item.service_type() === 'delivery' || item.service_type() === 'sentHome') {
                return 'Pay upon delivery';
            }

            if (item.service_type() === 'table') {
                return 'Pay at the premises';
            }

            if (item.service_type() === 'preparedHome') {
                return 'Pay in person';
            }

            if (item.service_type() === 'takeaway') {
                return 'In person at pickup';
            }

            return 'Not specified';
        });

        item.calculatePaymentPrice = ko.pureComputed(function () {
            var paypal_price = 0.37;
            var paypal_fee = 3.6;
            if (item.price() <= 0) {
                return 0;
            }
            var extra = (item.price() * paypal_fee / 100);
            return Math.round((paypal_price + extra + Number.EPSILON) * 100) / 100
        });

        item.calculateTotalPrice = ko.pureComputed(function () {
            if (item.price() <= 0) {
                return 0;
            }
            return item.price() + (item.transaction().isOnlinePayed ? item.calculatePaymentPrice() : 0);
        })

        item.calculateTotalProposedPrice = ko.pureComputed(function () {
            if (!item.proposal())
                return 0
            if (item.proposal().price() <= 0) {
                return 0
            }
            return item.proposal().price()
        })

        item.calculateTotalProposedTotal = ko.pureComputed(function () {
            if (!item.proposal())
                return 0
            if (item.proposal().total() <= 0) {
                return 0
            }
            return item.proposal().total()
        })

        item.discountCouponTooltip = ko.pureComputed(function () {
            if (!item.coupon) {
                return ''
            }
            var text = 'Discount Code: ' + item.coupon().name + '<br>'
            text += 'with a value of ' + item.coupon().discount + (item.coupon().type === 'percentage' ? '%' : '£')
            if (item.coupon().combinable)
                return text + ' is applied to the Total'
            return text + ' is applied only to items that are not already discounted'
        })

        item.discountPromotionTooltip = ko.pureComputed(function () {
            if (!item.discount_type()) {
                return ''
            }
            var text = 'The Promotional Offer with a value of  '
            text += item.discount() + (item.discount_type() === 'percentage' ? '%' : '£')
            if (item.is_total_discount())
                return text + ' is applied to the Total Request'
            return text + ' is applied only to items that are not already discounted'
        })

        return item
    }
}