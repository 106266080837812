function AddressModel(item) {
    var self = this;

    self.state_id = ko.observable(item ? item.state_id : null);
    self.province_id = ko.observable(item ? item.province_id : null);
    self.city_id = ko.observable(item ? item.city_id : null);
    self.country_id = ko.observable(172);
    self.latitude = ko.observable(item ? item.latitude : null);
    self.longitude = ko.observable(item ? item.longitude : null);
    self.street = ko.observable(item ? item.street : '');
    self.direction = ko.observable(item ? item.direction : '');
    self.number = ko.observable(item ? item.number : '');
    self.district = ko.observable(item ? item.district : '');
    self.postal_code = ko.observable(item ? item.postal_code : '');
    self.id = ko.observable(item ? item.id : null);
    self.is_shipping = ko.observable(item ? item.is_shipping : false);
    self.is_billing = ko.observable(item ? item.is_billing : false);
    self.is_primary = ko.observable(item ? item.is_primary : true);
    //@TODO valutare se rimuovere i campi del morphing
    self.addressable_id = ko.observable(item ? item.addressable_id : null);
    self.addressable_type = ko.observable(item ? item.addressable_type : null);

    self.state_name = ko.observable(item && item.state_name ? item.state_name : '');
    self.city_name = ko.observable(item && item.city_name ? item.city_name : '');
    self.province_name = ko.observable(item && item.province_name ? item.province_name : '');
    self.country_name = ko.observable(item && item.country_name ? item.country_name : 'italia');

    self.latitude.extend({required: true, number: true, notEqual: 0});
    self.longitude.extend({required: true, number: true, notEqual: 0});
    self.street.extend({required: true});
    self.district.extend({required: true});
    self.postal_code.extend({required: true, pattern: '^[a-zA-Z0-9 ]{1,10}$'});


    self.toString = function () {
        var string = "";

        if (self.street()) {
            string = string + " " + self.street();
        }
        if (self.number()) {
            string = string + " " + self.number();
        }
        if (self.postal_code())
            string = string + ", " + self.postal_code();
        if (self.district())
            string = string + ", " + self.district();
        if (self.state_name())
            string = string + ", " + self.state_name();
        if (self.province_name())
            string = string + ", " + self.province_name();
        if (self.city_name())
            string = string + ", " + self.city_name();
        if (self.country_name())
            string = string + ", " + self.country_name();

        return string;
    }
    return self;
}
