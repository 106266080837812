function OfferModel(item) {
    var self = this

    self.bundle = {}

    self.id = ko.observable(item ? item.id : null)
    self.cid = ko.observable(item ? item.cid : null)
    self.oid = ko.observable(item ? item.oid : null)
    self.type = ko.observable(item ? item.type : '')
    self.status = ko.observable(item ? item.status : 'draft')
    self.fan = ko.observable(item ? item.fan : '')
    self.name = ko.observable(item ? item.name : '')
    self.description = ko.observable(item ? item.description : '')
    self.descriptionShort = ko.observable(item ? item.descriptionShort : '')
    self.dateMode = ko.observable(item ? item.dateMode : '')
    self.dateNotes = ko.observable(item ? item.dateNotes : '')
    self.dateStart = ko.observable(item ? item.dateStart : null)
    self.dateEnd = ko.observable(item ? item.dateEnd : null)
    self.dateWod = ko.observable(item ? item.dateWod : 0)
    self.discountMinOrder = ko.observable(item ? item.discountMinOrder : null)
    self.discountMinCost = ko.observable(item ? item.discountMinCost : null)
    self.bookingSettings = ko.observable(item ? item.bookingSettings : 'no')
    self.conditions = ko.observable(item ? item.conditions : '')
    self.isLimited = ko.observable(item ? item.isLimited : 0)
    self.priceMax = ko.observable(item ? item.priceMax : 0)
    self.price = ko.observable(item ? item.price : 0)
    self.discount = ko.observable(item ? item.discount : 0)
    self.discountMode = ko.observable(item ? item.discountMode : null)
    self.menuMode = ko.observable(item ? item.menuMode : 'text')
    self.menuText = ko.observable(item ? item.menuText : null)
    self.courseCount = ko.observable(item ? parseInt(item.courseCount) : 0)
    self.minPeople = ko.observable(item ? item.minPeople : 0)
    self.amount = ko.observable(item ? item.amount : 0)
    self.amountDaily = ko.observable(item ? item.amountDaily : 0)
    self.amountAvailable = ko.observable(item ? item.amountAvailable : 0)
    self.serviceTakeAway = ko.observable(item ? (item.serviceTakeAway) : false)
    self.serviceDelivery = ko.observable(item ? (item.serviceDelivery) : false)
    self.serviceTable = ko.observable(item ? (item.serviceTable) : false)
    self.servicePreparedHome = ko.observable(item ? (item.servicePreparedHome) : false)
    self.serviceSentHome = ko.observable(item ? (item.serviceSentHome) : false)
    self.link = ko.observable(item ? item.link : null)
    self.linkText = ko.observable(item ? item.linkText : null)
    self.referrer_cid = ko.observable(item ? item.referrer_cid : null)
    self.isMine = ko.observable(item ? item.isMine : 1)
    self.otherAddress = ko.observable(item ? item.otherAddress : 0)
    self.ext_catering_id = ko.observable(item ? item.ext_catering_id : null)
    self.canteen = ko.observable(item ? item.canteen : 0)
    self.locked = ko.observable(item ? item.locked : 0)
    self.locked_fields = ko.observable(item ? item.locked_fields : null)
    self.fans = ko.observable(item ? item.fans : 0)
    self.num_activation = ko.observable(item ? item.num_activation : 0)
    self.activated_first_at = ko.observable(item ? item.activated_first_at : null)
    self.activated_last_at = ko.observable(item ? item.activated_last_at : null)
    self.visibility_points = ko.observable(item ? item.visibility_points : 0)
    self.popularity_points = ko.observable(item ? item.popularity_points : 0)
    self.discountCombinable = ko.observable(item ? item.discountCombinable : 0)
    self.discountExcluded = ko.observable(item ? item.discountExcluded : 0)
    self.discountTotalOrder = ko.observable(item ? item.discountTotalOrder : 0)
    self.checkAmount = ko.observable(item ? item.checkAmount : 0)
    self.cateringName = ko.observable(item ? item.cateringName : null)
    self.cateringTel = ko.observable(item ? item.cateringTel : null)
    self.lat = ko.observable(item ? item.lat : 0.0)
    self.lon = ko.observable(item ? item.lon : 0.0)
    self.linked_offer_id = ko.observable(item ? item.linked_offer_id : null)
    self.linked_catering_id = ko.observable(item ? item.linked_catering_id : null)
    self.cloned_id = ko.observable(item ? item.cloned_id : null)

    if (item && item.bundle) {
        self.bundle.seo = ko.observable(item.bundle.seo ? item.bundle.seo : null)
        self.bundle.imageProfile = ko.observable(item.bundle.imageProfile ? item.bundle.imageProfile : null)

    }

    self.printPrice = function () {
        if (self.type !== 'recipe' && self.type !== 'post')
            if (self.price > 0) {
                if (self.discountMode === 'percentage' && self.discount > 0) {

                    return "(" + self.priceMax + "£ -" + self.discount + "%)" + self.price + "£"
                }
                if (self.discountMode === 'currency' && self.discount > 0) {
                    return "(" + self.priceMax + "£ -" + self.discount + "£)" + self.price + "£"
                }
            }

        if (self.price === 0) {

            if (self.discountMode === 'percentage' && self.discount > 0)
                return "-" + self.discount + "%";
        }

        if (self.discountMode === 'currency' && self.discount > 0) {
            return "-" + self.discount + "£";
        }

        if (self.discount === 0) {
            if (self.type === "promotion-gift")
                return 'Gift';
            if (self.type !== "promotion-gift")
                return 'Free';
        }
    }

}

