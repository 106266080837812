function BookingItemModel(item) {
    var self = this;

    self.id = ko.observable(item ? item.id : null);
    self.booking_id = ko.observable(item ? item.booking_id : null);
    self.user_id = ko.observable(item ? item.user_id : null);
    self.bookable_id = ko.observable(item ? item.bookable_id : null);
    self.bookable_type = ko.observable(item ? item.bookable_type : null);
    self.name = ko.observable(item ? item.name : null);
    self.state = ko.observable(item ? item.state : null);
    self.amount = ko.observable(item ? item.amount : null);
    self.price = ko.observable(item ? item.price : null);
    self.discountable = ko.observable(item ? item.discountable : null);
    self.discount = ko.observable(item ? item.discount : null);
    self.discount_type = ko.observable(item ? item.discount_type : null);
    self.currency = ko.observable(item ? item.currency : null);
    self.item_type = ko.observable(item ? item.item_type : null);
    self.note = ko.observable(item ? item.note : null);
    self.error_data = ko.observable(item ? item.error_data : null);
    self.error_code = ko.observable(item ? item.error_code : null);

    if (item && item.item_type === 'dish') {
        self.item_data = ko.observable(item ? ko.mapping.toJS(new DishModel(item.item_data)) : null);
    } else {
        self.item_data = ko.observable(item ? ko.mapping.toJS(new OfferModel(item.item_data)) : null);
    }

    self.bookable = item ? item.bookable : null; //item object

    //@TODO separare tutto quello che non fa parte del model in un processor
    self.visibleDetails = ko.observable(false);

    self.toogleDetails = function () {
        self.visibleDetails(!self.visibleDetails());
    };

    self.disableAmount = ko.computed(function () {
        if (self.item_type() && (self.item_type() === 'promotion' || self.item_type() === 'gift'))
            return true;
        return self.error_code() && self.error_code() !== 'dish.amount_min' && self.error_code() !== 'dish.amount_max';

    });

    self.error_message = ko.observable(item ? item.error_message : null);

    self.error = ko.computed(function () {
        if (self.error_code()) {
            self.visibleDetails(true);
            return true;
        }
        return false;
    });

    self.printPrice = function () {

        if (self.item_type() === 'promotion' && self.discount() > 0) {
            return self.discount_type() === 'percentage' ? "-" + self.discount() + "%" : "-" + self.discount() + "£";
        }

        if (self.item_type() === 'gift') {
            return 'Omaggio';
        }

        return self.price() > 0 ? self.price() + "£" : 'Gratis';
    }

    self.incrementAmount = function () {self.amount(self.amount() + 1)}
    self.decrementAmount = function () {self.amount(self.amount() - 1)}
}