function GeocoderModule() {
    var self = this;
    self.markers = [];
    self.map = null;
    self.geocoder = null;
    self.address = null;

    self.geocode = function (callback) {
        self.geocoder.geocode({'address': self.address.toString()}, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
                var location = results[0].geometry.location;
                if (self.map) {

                    self.map.setCenter(location);
                    removeMarkers();
                    addMarker(location.lat(), location.lng(), 16);
                    showMarkers();
                }
                if (callback) {
                    callback({
                        lat: location.lat(),
                        lon: location.lng()
                    });
                }
            } else {
                callback(null);
            }
        });
    };

    function showMarkers() {
        for (var i in self.markers) {
            self.markers[i].setMap(self.map);
        }
    }

    function removeMarkers() {
        for (var i in self.markers) {
            self.markers[i].setMap(null);
        }
        self.markers = [];
    }

    self.init = function (elementId, address) {
        self.geocoder = new google.maps.Geocoder();
        self.address = address;

        if (elementId) {
            var element = document.getElementById(elementId);
            if (element) {
                if (self.address && self.address.latitude() && self.address.longitude()) {
                    setMap(element, self.address.latitude(), address.longitude(), 16);
                    addMarker(self.address.latitude(), self.address.longitude(), 16);
                } else {
                    setMap(element, 54.0, -2.0, 14);
                    addMarker(54.0, -2.0, 14);
                }
                showMarkers();
            }
        }

        return self;
    };

    function handleEvent(event) {
        self.address.latitude(parseFloat(event.latLng.lat()));
        self.address.longitude(parseFloat(event.latLng.lng()));
    }

    function addMarker(lat, lon, zoom, draggable) {
        var myLatLng = new google.maps.LatLng(lat, lon);
        if (!draggable)
            draggable = true;

        var marker = new google.maps.Marker({
            map: self.map,
            position: myLatLng,
            draggable: draggable
        });

        if (draggable) {
            marker.addListener('dragend', handleEvent);
        }

        self.markers.push(marker);
        return marker;
    }

    function setMap(element, lat, lon, zoom) {
        var myLatLng = new google.maps.LatLng(lat, lon);
        var mapOptions = {
            zoom: zoom,
            center: myLatLng,
        };
        self.map = new google.maps.Map(element, mapOptions);
        return self.map;
    }
}