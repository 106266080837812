function CartModule (bookingModule) {
  var self = this

  self.cart = ko.observable(null)
  self.orders = ko.observable()
  self.cateringId = ko.observable()
  self.isFavorite = ko.observable(false)
  self.loading = ko.observable(false)
  self.loadingAdd = ko.observable(false)
  self.bookingAddressValid = ko.observable(false)
  self.bookingAddressChecked = ko.observable(false)
  self.billingDataValid = ko.observable(null)
  self.addressBookingVM = ko.observable(null)
  self.addressBillingVM = ko.observable(null)
  self.latestAddress = ko.observable(null)
  self.userAddress = ko.observable(null)
  self.bookingErrorReason = ko.observable()
  self.isBookable = ko.observable(false)
  self.noteVisible = ko.observable(false)
  self.serviceTimeRanges = ko.observableArray([])
  self.listOffers = ko.observableArray([])
  self.listDishes = ko.observableArray([])
  self.listDonations = ko.observableArray([])
  self.selectedDonationProgram = ko.observable()
  self.showTotalToPay = ko.observable(false)
  self.donationDetails = ko.observable(false)
  self.activeDonation = ko.observable(false)
  self.donationAmount = ko.observable(0.0)
  self.donationDescription = ko.observable('')
  self.selectedOffer = ko.observable(null)
  self.selectedDish = ko.observable(null)
  self.otherSeat = ko.observable(false)
  self.userCredits = ko.observable(0.0)
  self.paymentType = ko.observable('offline')
  self.couponName = ko.observable('')

  self.bookingModule = bookingModule ? bookingModule : new BookingModule()

  self.isEmpty = ko.computed(function () {
    if (!self.cart())
      return true
    if (self.cart().service_date())
      return false
    return !(self.cart().items && self.cart().items().length > 0)

  })

  self.messages = {
    'booking.service_type': 'Select a service type',
    'booking.service.unsupported': 'Service type no longer available, please select another',
    'booking.datetime': 'No available dates found for the items you added to your request. Check availability and service options again',
    'booking.service_date': 'To proceed, you must specify the date',
    'booking.service_time': 'To proceed, you must specify the time',
    'booking.seats': 'To proceed, you must specify and/or confirm the number of people',
    'promotion.minPeople': 'A minimum number of reserved seats is required to receive the discount at checkout (table service)',
    'offer.minPeople': 'One of the offers you selected must be requested in a larger quantity',
    'offer.daily': 'One of the offers you selected exceeds the available quantity or is no longer available on the selected day',
    'offer.amount': 'One of the offers you selected is not available in the indicated quantity. Try reducing it',
    'booking.too-late': 'The time you selected is too close or has already passed',
    'booking.items.empty': 'To proceed with this request, you need to add items to the request',
    'booking.items.dirty': 'We are sorry, but one or more items you have entered in your request are invalid. To continue, please remove them.',
    'item.unavailable': 'We are sorry, but one of the items you added to your request is no longer available',
    'item.dirty': 'The item has been Added to the Reservation Request | Order but has some incompatibilities. Check the detailed message',
    'offer.service.unsupported': 'One of the items in your request is not available for the type of service you selected',
    // 'booking.delivery.distance': 'Warning: this venue does not deliver to the address you provided.',
    'booking.address': 'Confirm the address before proceeding with this request',
    'booking.guest': 'Sign in and check your email to confirm',
    'booking.account-not-active': 'Sign in and check your email to confirm',
  }

  self._serviceTimeRanges = [
    {label: 'Night', start: '00:00', end: '04:00'},
    {label: 'Morning', start: '04:01', end: '10:30'},
    {label: 'Day', start: '10:31', end: '15:30'},
    {label: 'Afternoon', start: '15:31', end: '17:30'},
    {label: 'Evening', start: '17:31', end: '22:30'},
    {label: 'Late night', start: '22:31', end: '24:00'}
  ]

  self.startDate = ko.computed(function () {
    if (self.cart() && self.cart().dates) {
      return moment.unix(self.cart().dates.dateStart).toDate()
    }
    return new Date()
  })

  self.serviceTimes = ko.pureComputed(function () {

    if (!self.cart().service_date()) {
      return null
    }

    if (!self.cart().dates) {
      return null
    }

    self.serviceTimeRanges([])

    // We'll need a temporary array to store the time ranges,
    // and another array to keep memory of those ranges
    // that we need to show. For example, when range 1, 2, 4 and 6
    // actually contain a valid time, the indexes array would be
    // something like [0, x, y, 0, k, 0, j], where every variable
    // is greater than zero (it will be the number of occurrence,
    // even if we don't need that value).
    var temp = self._serviceTimeRanges.slice(0)
    var indexes = [0, 0, 0, 0, 0, 0]

    // Since we assumed a day change has occurred, we need the
    // day-of-week index. We store this in the 'i' var.
    var theServiceDate = moment.unix(self.cart().service_date())
    var i = theServiceDate.isoWeekday()

    if (!self.cart().dates.schedule || !self.cart().dates.schedule[i]) {
      return null
    }
    var dailySchedule = self.cart().dates.schedule[i]

    var whatDayIsIt = moment().format('YYYY MM DD')
    //quanti minuti prima della prenotazione
    var previousMinutes = 15
    if (self.cart().service_type() && self.cart().service_type() === 'delivery')
      previousMinutes = 30
    if (self.cart().service_type() && self.cart().service_type() === 'table')
      previousMinutes = 5

    var whatTimeIsIt = moment().add(previousMinutes, 'm').format('HH:mm')

    // We now need to find which ranges in the self._serviceTimeRanges array
    // we want to show in the UI (keep in mind that self._serviceTimeRanges has
    // been copied in the temp array). For this purpose we need to compare
    // each allowed time to the self._serviceTimeRanges limits (start, end).

    var dailyScheduleClean = []
    for (var j in dailySchedule) {

      // Skip times that are already past.
      if (theServiceDate.format('YYYY MM DD') === whatDayIsIt && whatTimeIsIt >= dailySchedule[j]) {
        continue
      }

      dailyScheduleClean.push(dailySchedule[j])
      for (var k in temp) {
        var range = temp[k]

        if (dailyScheduleClean[dailyScheduleClean.length - 1] >= range.start && dailySchedule[j] <= range.end) {
          indexes[k]++
        }
      }
    }

    for (k in indexes) {
      // If this index contains atleast one entry we have to push it in the final array.
      if (indexes[k]) {
        self.serviceTimeRanges.push(temp[k])
      }
    }

    return dailyScheduleClean

  })

  self.message = ko.computed(function () {
    if (!self.cart || !self.cart()) {
      return ''
    }
    if (self.bookingErrorReason() === 'booking.delivery.priceMin') {
      return 'The minimum total of the request for delivery is ' + self.cart().catering.serviceDeliveryPriceMin + ' ' + '\u20AC'
    }
    return self.messages[self.bookingErrorReason()]
  })

  self.note = ko.computed(function () {
    return self.noteVisible() || (self.cart() && self.cart().note() && self.cart().note().length)
  })

  self.hasConflictDate = ko.computed(function () {
    return self.cart() && self.cart().service_type() && (!self.cart().dates || !self.cart().dates.schedule)
  })

  self.addressLabel = ko.computed(function () {
    if (!self.cart())
      return 'Address'

    if (self.cart().service_type() === 'delivery') {
      return 'Delivery address'
    }

    if (self.cart().service_type() === 'sentHome') {
      return 'Shipping address'
    }

    if (self.cart().service_type() === 'preparedHome') {
      return 'Service address'
    }

    return 'Address'
  })

  self.offersDefaultOption = ko.pureComputed(function () {
    if (self.listOffers().length <= 0)
      return 'There are no offers and deals available'
    return 'Select offers and deals'
  })

  self.showAllCateringOffers = function () {
    var url = self.cart().catering.bundle.seo + '#tab-offers?r=' + (+new Date())
    window.history.pushState("", "", url);
    location.replace(url)
    window.location.reload()
  }

  self.showAllCateringDishes = function () {
    var url = self.cart().catering.bundle.seo + '#tab-menu?r=' + (+new Date())
    window.history.pushState("", "", url);
    location.replace(url)
    window.location.reload()
  }

  self.calculatePaymentPrice = ko.computed(function () {
    var paypal_price = 0.37
    var paypal_fee = 3.6
    if (!self.cart() || self.cart().price() <= 0) {
      return 0
    }
    var extra = (self.cart().price() * paypal_fee / 100)
    return Math.round((paypal_price + extra + Number.EPSILON) * 100) / 100
  })

  self.calculateTotalPrice = ko.computed(function () {
    if (!self.cart() || self.cart().price() <= 0) {
      return 0
    }
    return self.cart().price() + (self.paymentType() !== 'online' ? 0 : self.calculatePaymentPrice())
  })

  self.usableCreditsTextLabel = ko.pureComputed(function () {
      return "You have " + self.userCredits().toFixed(2) + "£ in FanCredits: would you like to use them to reduce the total amount to pay?"
  })

  self.usableCreditsText = ko.pureComputed(function () {
    var credit = self.userCredits()
    if (self.cart().extra_discount_price() > 0) {
      if (self.userCredits() >= self.cart().extra_discount_price())
        credit = self.userCredits() - self.cart().extra_discount_price()
      return 'You have used ' + self.cart().extra_discount_price() + '£ of your FanCredits. You still have ' + credit + '£'
    }

    if (self.userCredits() >= self.cart().price())
      credit = self.cart().price() + self.cart().extra_discount_price()
    if (self.cart().subtotal > 0)
      return 'You can use ' + credit.toFixed(2) + '£ of your available FanCredits'

    return 'To use them, simply add <a href="' + self.cart().catering.bundle.seo + 'r=' + (+new Date()) + '#tab-offers">Offers, Deals</a> ' +
        'and/or <a href="' + self.cart().catering.bundle.seo + 'r=' + (+new Date()) + '#tab-menu">Items from  ' + self.cart().catering.menuOrList + '</a> ' +
        'to your request before submitting it.'

  })

  self.priceTotalDiscount = ko.pureComputed(function () {
    var discount = self.cart().subtotal() - self.cart().price()
    return discount > 0 ? -discount.toFixed(2) : 0
  })

  self.priceDiscountPromotion = ko.pureComputed(function () {
    var discount = self.cart().discountPromotion()
    return discount > 0 ? -discount.toFixed(2) : 0
  })

  self.priceExtraDiscount = ko.pureComputed(function () {
    var discount = self.cart().extra_discount_price()
    return discount > 0 ? -discount.toFixed(2) : 0
  })

  self.priceDiscountCoupon = ko.pureComputed(function () {
    var discount = self.cart().discountCoupon()
    return discount > 0 ? -discount.toFixed(2) : 0
  })

  self.discountCouponTooltip = ko.pureComputed(function () {
    if (!self.cart().coupon) {
      return ''
    }
    var text = 'Discount code: ' + self.cart().coupon().name + '<br>'
    text += 'with a value of ' + self.cart().coupon().discount + (self.cart().coupon().type === 'percentage' ? '%' : '£')
    if (self.cart().coupon().combinable)
      return text + ' is applied to the total'
    return text + ' is applied only to items that are not already discounted'
  })

  self.servicePriceLabel = ko.pureComputed(function () {
    if (self.cart().service_type() === 'delivery') {
      return 'Delivery'
    }

    if (self.cart().service_type() === 'sentHome') {
      return 'Shipping'
    }

    if (self.cart().service_type() === 'preparedHome') {
      return 'Service'
    }

    return 'Service'
  })

  self.activeDonation.subscribe(function (value) {
    if (!value) {
      self.paymentType('offline')
      self.disapplyDonation()
    } else {
      self.paymentType('online')
    }
  })

  self.toggleCredits = function () {
    if (self.cart().extra_discount_price() <= 0)
      self.setCredits()
    else
      self.unsetCredits()
  }

  self.selectedDonationProgram.subscribe(function (item) {
    if (item && self.activeDonation()) {
      self.loading(true)
      return rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/donations', { 'id': item })
        .then(function (response) {
          if (response.success) {
            self.donationDescription(response.data.donationProgram.description)
            self.update()
          } else {
            if (response.message)
              pNotify(response.message, 'danger')
          }
          self.loading(false)
        })
        .catch(function () {
          self.loading(false)
          pNotify('It was not possible to apply the donation', 'danger')
        })
      }
    }
  )

  self.donationAmount.subscribe(function (item) {
    if (item && self.activeDonation()) {
      if (self.cart().donation() && item !== self.cart().donation().amount)
        self.applyDonationAmount(item)
    }
  })

  /**
   * read/write booking service date
   */
  self.dateTimepicker = ko.pureComputed({
    read: function () {
      return self.cart().service_date() ? moment.unix(self.cart().service_date()).toDate() : null
    },
    write: function (v) {
      self.cart().service_date(v ? moment(v).format('X') : null)
      self.cart().service_time(null)
      self.update()
    }
  })

  self.addressChanged = ko.computed(function () {
    if (!self.addressBookingVM() || !self.addressBookingVM().address)
      return
    if (!self.addressBookingVM().addressComplete() || self.addressBookingVM().address.latitude() || self.addressBookingVM().address.longitude()) {
      self.bookingAddressValid(false)
      self.bookingAddressChecked(false)
    }
  })

  self.setLatestAddress = function () {
    self.addressBookingVM(new AddressModule(self.latestAddress()))
    self.confirmAddressBooking()
  }

  self.setUserAddress = function () {
    self.addressBookingVM(new AddressModule(self.userAddress()))
    self.confirmAddressBooking()

  }

  self.printDeliveryInfo = ko.computed(function () {
    var infoArray = []
    if (self.cart() && self.cart().catering) {

      if (self.cart().catering.serviceDelivery && self.cart().service_type() === 'delivery') {
        if (self.cart().catering.serviceDeliveryAverageTime > 0) {
          infoArray.push('Average delivery in ' + self.cart().catering.serviceDeliveryAverageTime + ' min')
        }
        if (self.cart().catering.serviceDeliveryPriceMin > 0) {
          infoArray.push('Minimum cost for delivery ' + self.cart().catering.serviceDeliveryPriceMin + '£')
        }
        if (self.cart().catering.serviceDeliveryFreeThreshold > 0) {
          infoArray.push('Free delivery for amounts higher than ' + self.cart().catering.serviceDeliveryFreeThreshold + '£')
        }
        if (self.cart().catering.serviceDeliveryPrice > 0) {
          infoArray.push('Cost of delivery ' + self.cart().catering.serviceDeliveryPrice + '£')
        } else {
          infoArray.push('Free delivery')
        }
        if (self.cart().catering.ranges && self.cart().catering.ranges[0]) {
          infoArray.push('Delivery range ' + self.cart().catering.ranges[0].distance + ' Mi')
        }
        if (self.cart().catering.serviceDeliveryInfo !== '') {
          infoArray.push('Info: ' + self.cart().catering.serviceDeliveryInfo)
        }
      }

    }

    return infoArray.join(' | ')
  })

  self.printSentInfo = ko.computed(function () {
    var infoArray = []
    if (self.cart() && self.cart().catering) {

      if (self.cart().catering.serviceSentHome && self.cart().service_type() === 'sentHome') {
        if (self.cart().catering.serviceSentPriceMin > 0) {
          infoArray.push('Minimum expedition expenditure ' + self.cart().catering.serviceSentPriceMin + '£')
        }
        if (self.cart().catering.serviceSentFreeThreshold > 0) {
          infoArray.push('Free shipping for amounts higher than ' + self.cart().catering.serviceSentFreeThreshold + '£')
        }
        if (self.cart().catering.serviceSentPrice > 0) {
          infoArray.push('Shipping cost ' + self.cart().catering.serviceSentPrice + '£')
        } else {
          infoArray.push('Free shipping')
        }
        if (self.cart().catering.serviceSentInfo !== '') {
          infoArray.push('Info: ' + self.cart().catering.serviceSentInfo)
        }
      }
    }

    return infoArray.join(' | ')
  })

  self.printMessageFreeThreshold = ko.computed(function () {
    var threshold = 0
    if (self.cart() && self.cart().catering) {
      if (self.cart().catering.serviceSentHome && self.cart().service_type() === 'sentHome') {
        threshold = self.cart().catering.serviceSentFreeThreshold - self.cart().subtotal()
        if (threshold > 0) {
          return 'You only need ' + threshold + '£ more to get free shipping.'
        }
      }
      if (self.cart().catering.serviceDelivery && self.cart().service_type() === 'delivery') {
        threshold = self.cart().catering.serviceDeliveryFreeThreshold - self.cart().subtotal()
        if (threshold > 0) {
          return 'You only need ' + threshold + '£ more to get free delivery.'
        }
      }
    }
    return ''
  })

  self.optionSelectPrintOffer = function (item) {
    if (item.type === 'recipe' || item.type === 'post') {
      return item.name
    }
    var optionName = item.name + ' | '
    if (item.price > 0) {
      if (item.discountMode === 'percentage' && item.discount > 0) {

        return optionName + item.price + '£' + '  (' + item.priceMax + '£ -' + item.discount + '%)'
      }
      if (item.discountMode === 'currency' && item.discount > 0) {
        return optionName + item.price + '£' + '  (' + item.priceMax + '£ -' + item.discount + '£)'
      }

      if (item.discount === 0) {
        return optionName + item.price + '£'

      }
    }

    if (item.price === 0) {
      if (item.discountMode === 'percentage' && item.discount > 0)
        return optionName + '-' + item.discount + '%'

      if (item.discountMode === 'currency' && item.discount > 0) {
        return optionName + '-' + item.discount + '£'
      }

      if (item.discount === 0) {
        if (item.type === 'promotion-gift')
          return optionName + 'Gift'
        if (item.type !== 'promotion-gift')
          return optionName + 'Free'
      }
    }
  }

  self.userCreditsInfo = function () {
    Swal.fire({
        html: '<p class="text-md">You can use your FanCredits to get a reduction on the total amount you will pay (online or in person, depending on the payment options accepted by the establishment and the one you selected).<br><br>If the offers, deals, and/or items from '
          + self.cart().catering.menuOrList
            + ' that you actually receive at the time of the service change due to different agreements between you and the food service provider (e.g., because an item from '
          + self.cart().catering.menuOrList
            + ' is no longer available), you will be entitled to the same reduction on the new total amount to pay, equivalent to the quantity of FanCredits used when submitting the request.</p>',
      showCancelButton: false,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn btn-success mg-r-xs',
      },
      buttonsStyling: false
    })
  }

  self.optionSelectPrintDish = function (item) {
    var optionName = item.name + ' | '
    if (item.price_sale > 0) {
      if (item.discount > 0) {

        return optionName + item.price_sale + '£' + ' (' + item.price + '£ -' + item.discount + '%)'
      }
      if (item.discount === 0) {
        return optionName + item.price_sale + '£'

      }
    }

    if (item.price_sale === 0) {
      if (item.isPriceHidden) {
        return optionName + 'Price on request'
      }
      if (item.discount > 0) {
        return optionName + '-' + item.discount + '%'
      }
      return optionName + 'Free'
    }
    return item.name
  }

  self.toggleNote = function () {
    if (self.noteVisible()) {
      self.noteVisible(false)
    } else {
      self.noteVisible(true)
    }
  }

  self.otherSeatToggle = function () {
    if (!self.otherSeat()) {
      self.otherSeat(true)
      self.setSeats(self.cart().seats() ? self.cart().seats() : 0)
    } else {
      self.otherSeat(false)
      self.setSeats(0)
    }
  }

  self.loadLatestAddresses = function () {
    return rest('GET', '/api/v2/bookings/' + self.cart().id() + '/cart/addresses')
        .then(function (response) {
          if (response.success) {
            self.latestAddress(response.data.latestAddress)
            self.userAddress(response.data.userAddress)
          }
          return response
        })
  }

  self.loadByCatering = function (create) {
    if (!self.cart() || self.cart().catering_id() !== self.cateringId()) {
      self.cart(null)
      self.loading(true)

      return rest('GET', '/api/v2/bookings/cart/catering/' + self.cateringId() + (create ? '/?create=1' : ''))
        .then(function (response) {
          if (!response.success) {
            self.bookingErrorReason(response.status)
            self.isBookable(false)
          } else {
            self.bookingErrorReason('')
            self.isBookable(true)
          }
          if (response.data)
            self.setCart(response.data)
          self.loading(false)
          return response
        })
        .then(function () {
          bookingVM.bookingModule.loadDrafts()
        })
        .catch(function (response) {
          self.loading(false)
          return response
        })
    }
    return Promise.resolve({ state: 'success', data: self.cart() })
  }

  self.loadDraftById = function (cartId) {
    if (!cartId) {
      return
    }
    self.loading(true)

    return rest('GET', '/api/v2/bookings/cart/' + cartId)
      .then(function (response) {
        if (!response.success) {
          self.bookingErrorReason(response.status)
          self.isBookable(false)
        } else {
          self.bookingErrorReason('')
          self.isBookable(true)
        }
        if (response.data) {
          self.setCart(response.data)
          self.cateringId(response.data.catering_id)
        }
        self.loading(false)
        return response
      })
      .then(function (response) {
        bookingVM.bookingModule.loadDrafts()
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })

  }

  self.loadCateringOffers = function () {
    self.loading(true)
    return rest('GET', '/api/v2/bookings/' + self.cart().id() + '/cart/offers')
      .then(function (response) {
        if (response.success)
          self.listOffers(response.data)
        else
          self.listOffers([])
        self.loading(false)
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })

  }

  self.loadCateringDishes = function () {
    self.loading(true)
    return rest('GET', '/api/v2/bookings/' + self.cart().id() + '/cart/dishes')
      .then(function (response) {
        if (response.success)
          self.listDishes(response.data)
        else
          self.listDishes([])
        self.loading(false)
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })

  }

  self.loadUserCredits = function () {
    return rest('GET', '/api/v2/bookings/' + self.cart().id() + '/cart/credits')
      .then(function (response) {
        if (response.success)
          self.userCredits(response.data)
        return response
      })
      .catch(function (response) {
        self.userCredits(0.0)
        return response
      })

  }

  self.setItemAmount = function (item, amount) {
    if (!item || amount === undefined) {
      return
    }
    var data = {
      item: item,
      amount: amount >= 0 ? amount : 0
    }
    self.loading(true)
    return rest('PUT', '/api/v2/bookings/' + self.cart().id() + '/cart/items/amount', data)
      .then(function (response) {
        if (response.success && response.data.item)
          pNotify('The amount of  ' + response.data.item.name + ' has been modified')
        self.applyResponseBooking(response)
        self.loading(false)
      })
        .catch(function (err) {
          self.loading(false)
        })
  }

  self.checkServiceRequiresAddress = function () {
    var services = ['delivery', 'sentHome', 'preparedHome']

    return services.includes(self.cart().service_type())
  }

  self.applyCoupon = function () {
    return rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/coupons', { 'name': self.couponName })
      .then(function (response) {
        if (response.success)
          self.update()
        else {
          if (response.message)
            pNotify(response.message, 'danger')
        }
      })
      .catch(function () {
        pNotify('It was not possible to apply the coupon', 'danger')
      })
  }

  self.disapplyCoupon = function () {
    return rest('DELETE', '/api/v2/bookings/' + self.cart().id() + '/cart/coupons/')
      .then(function (response) {
        if (response.success)
          self.update()
      }).catch(function (err) {

      })
  }

  self.applyDonationAmount = function (value) {
    return rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/donations', {
      'id': self.selectedDonationProgram(),
      'amount': value
    })
      .then(function (response) {
        if (response.success) {
          if (response.status === 'min_amount')
            pNotify('Minimum composition of this donation is: ' + response.data.donationProgram.min_amount + '£', 'warning')
          self.update()
        } else {
          if (response.message)
            pNotify(response.message, 'danger')
        }
      })
      .catch(function () {
        pNotify('It was not possible to apply the donation', 'danger')
      })
  }

  self.disapplyDonation = function () {
    return rest('DELETE', '/api/v2/bookings/' + self.cart().id() + '/cart/donations/')
      .then(function (response) {
          if (response.success) {
            self.donationAmount(0.0)
            self.donationDescription('')
            self.selectedDonationProgram(null)
            self.update()
          }
        }
      ).catch(function (err) {

      })
  }

  self.loadDonationPrograms = function () {
    self.loading(true)
    return rest('GET', '/api/v2/donation_programs')
      .then(function (response) {
        if (response.success)
          self.listDonations(response.data)
        else
          self.listDonations([])
        self.loading(false)
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })
  }

  self.setCart = function (data) {

    self.cart(new BookingModel(data))
    if (self.checkServiceRequiresAddress() && !self.addressBookingVM()) {
      self.setAddressBooking()
    }
    /*    if (!self.addressBookingVM())//@todo caricare solo quando necessario
          self.setAddressBilling()*/

    ko.utils.arrayForEach(self.cart().items(), function (item) {
      item.amount.subscribe(function (v) {
        if (v >= 0) {
          self.setItemAmount(item, v)
        }
      })
    })

    if (self.cart().seats() > 10)
      self.otherSeat(true)

    if (self.cart().coupon())
      self.couponName(self.cart().coupon().name)

    if (self.cart().donation()) {
      self.donationAmount(self.cart().donation().amount)
      self.selectedDonationProgram(self.cart().donation().donation_id)
      self.activeDonation(true)
    }

    if (self.cart().dirty()) {
      pNotify('Warning: The establishment has made changes. Please review all the information in your request before submitting it!', 'warning')

    }
  }

  self.setItemNote = function (item) {
    Swal.fire({
      input: 'textarea',
      customClass: {
        container: 'swal-modal'
      },
      inputValue: item.note() ? item.note() : '',
      inputPlaceholder: 'Write a note!',
      inputAttributes:
        {
          'aria-label':
            'Write a note!'
        }
      ,
      showCancelButton: true
    }).then(function (result) {
      if (result.value) {
        var data = {
          item: item,
          note: result.value
        }

        return rest('PUT', '/api/v2/bookings/' + self.cart().id() + '/cart/items/note', data)
            .then(function (response) {
            item.note(result.value)
          })

      }
    })
  }

  self.update = function () {
    if (!self.cart())
      return $.Deferred().fail()

    self.loading(true)
    return rest('PUT', '/api/v2/bookings/' + self.cart().id(), self.cart)
      .then(function (response) {
        if (!response.data.booking)
          response.data.booking = response.data
        self.applyResponseBooking(response)
        self.loading(false)
        return response
      })
      .catch(function (response) {
        self.loading(false)
        return response
      })
  }

  self.applyResponseBooking = function (response) {
    if (!response.success) {
      self.bookingErrorReason(response.status)
      self.isBookable(false)
    } else {
      self.bookingErrorReason('')
      self.isBookable(true)
    }
    self.setCart(response.data.booking)
  }

  self.reload = function () {
    return self.loadByCatering()
  }

  self.toggleService = function (service) {
    if (self.cart().service_type() !== service) {
      self.resetDateTime()
    }
    self.setService(service)
  }

  self.resetDateTime = function () {
    self.cart().service_date(null)
    self.cart().service_time(null)
  }

  self.setSelectedTime = function (value) {
    if (self.cart() && self.cart().service_time)
      self.cart().service_time(value)

    self.update()

  }

  self.addItemById = function (itemId, itemType, amount) {
    self.loadingAdd(true)
    if (!itemId || !itemType || !self.cart() || !self.cart().id()) {
      pNotify('Impossible to add this product to the request', 'error')
      self.loading(false)
      return Promise.reject()
    }

    var data = {
      id: itemId,
      type: itemType,
      amount: amount ? amount : 1
    }
    return rest('PUT', '/api/v2/bookings/' + self.cart().id() + '/cart/items/', data)
      .then(function (response) {
        self.update()
        return response
      })
      .catch(function (response) {
        console.log(response)
        return response
      })
      .always(function () {
        sendFacebookEvent('track', 'AddToCart', { item_id: itemId, type: itemType })
        self.loadingAdd(false)
      })
  }

  self.addItemFromSelectOffer = function () {
    if (self.selectedOffer() > 0)
      return self.addItemById(self.selectedOffer(), 'offer')
        .then(function () {
          pNotify('The offer was added to the request', 'success')
        })
    else {
      pNotify('Select a offer', 'error')
    }
  }

  self.addItemFromSelectDish = function () {
    if (self.selectedDish() > 0)
      return self.addItemById(self.selectedDish(), 'dish')
        .then(function () {
          pNotify('The item was added to the request', 'success')
        })
    else {
      pNotify('Select a item', 'error')
    }
  }

  self.deleteItem = function (item) {
    if (!item) {
      pNotify('Error removing the item. Please try reloading the page', 'error')
      return

    }
    return rest('DELETE', '/api/v2/bookings/' + self.cart().id() + '/cart/items', item).then(function (response) {
      if (response.success)
        pNotify(response.data.name + '  removed from your request')
      else
        pNotify(self.messages[response.status], 'error')

      self.update()
    })
  }

  self.setAddressBooking = function () {
    if (!self.addressBookingVM()) {
      if (self.cart().address()) {
        self.addressBookingVM(new AddressModule(self.cart().address()))
        self.confirmAddressBooking()
        return
      }

      if (trovacigusto.userLogged()) {
        rest('GET', '/api/v2/users/' + trovacigusto.userLogged().id + '/address?type=shipping')
          .then(function (response) {
            self.addressBookingVM(new AddressModule(response.data))
            self.confirmAddressBooking()
          })
      } else {
        rest('GET', '/api/v2/guests/address').then(function (response) {
          self.addressBookingVM(new AddressModule(response.data))
          self.confirmAddressBooking()
        })
      }
    } else {
      self.confirmAddressBooking()
    }
  }

  self.setAddressBilling = function () {
    if (self.cart() && self.cart().billing)
      var address = new AddressModule(ko.mapping.toJS(self.cart().billing))
    else
      address = new AddressModule()

    self.addressBillingVM(address)
  }

  self.setService = function (service) {
    if (self.checkServiceRequiresAddress()) {
      self.setAddressBooking()
    }

    if (self.cart() && self.cart().service_type !== service) {
      self.cart().service_type(service)
      self.update()
    }

  }

  self.testDatesValid = function (date) {
    if (!self.cart() || !self.cart().dates || self.cart().dates.length <= 0) {
      return false
    }

    var momentTime = moment.tz(date, 'Europe/Rome')

    var time = momentTime.format('X')

    if (time > self.cart().dates.dateEnd) {
      console.log(date + ' >')
      return false
    }

    for (var i in self.cart().dates.schedule) {
      if (parseInt(i) === parseInt(momentTime.isoWeekday())) {
        return true
      }
    }
    return false
  }

  self.setCredits = function () {
    if (self.userCredits() > 0 && self.cart().extra_discount_price() <= 0) {
      return rest('PUT', '/api/v2/bookings/' + self.cart().id() + '/cart/credits')
        .then(function (response) {
          if (response.success) {
            self.setCart(response.data)
          }
        })
        .catch(function (response) {

          return response
        })

    }
  }

  self.unsetCredits = function () {
    if (self.cart().extra_discount_price() > 0 && self.cart().extra_discount_reason() === 'creditsUsage') {
      return rest('DELETE', '/api/v2/bookings/' + self.cart().id() + '/cart/credits')
        .then(function (response) {
          if (response.success) {
            self.setCart(response.data)
          }
        })
        .catch(function (response) {
          return response
        })
    }
  }

  self.confirmAddressBooking = function () {
    if (!self.addressBookingVM() || !self.addressBookingVM().addressComplete()) {
      return
    }

    var data = {
      address: self.addressBookingVM().address,
      userId: trovacigusto.userLogged() ? trovacigusto.userLogged().id : null,
      cateringId: self.cateringId(),
    }
    self.bookingAddressChecked(false)
    rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/address/', data)
      .then(function (response) {
        if (!response.success) {
          self.bookingAddressValid(false)
        } else {
          self.bookingAddressValid(true)
        }
        self.bookingAddressChecked(true)
        self.update()
      })
  }

  self.confirmBillingData = function () {
    var data = {
      address: self.addressBillingVM().address,
      userId: trovacigusto.userLogged() ? trovacigusto.userLogged().id : null,
      cateringId: self.cateringId(),
      billing: self.cart().billing
    }

    rest('POST', '/api/v2/bookings/' + self.cart().id() + '/cart/billing/', data)
      .then(function (response) {
        if (response.success) {
          pNotify('The billing data are correct.')
        } else {
          pNotify('The billing data are not correct. Check all fields', 'warning')
        }
        self.billingDataValid(response.success)
      }).then(function () {

    })
  }

  self.setSeats = function (number) {
    if (self.cart()) {
      if (!isNaN(number)) {
        self.cart().seats(number)
      }
      self.update()
    }
  }

  self.requestBooking = function () {
    return self.update()
      .then(function (response) {
        if (response.success) {
          self.loading(true)
          return self.bookingModule.requested(self.cart().id())
            .then(function (response) {
              self.bookingModule.notifyResponse(response)
              self.loading(false)
              sendFacebookEvent('track', 'Purchase', { currency: 'EUR', value: self.cart().price() })
              return response
            })
            .catch(function (response) {
              self.loading(false)
              return response
            })
        }
        return response
      })
      .catch(function (response) {
        return response
      })
  }

  self.addFavoriteCatering = function () {
    if (!trovacigusto.userLogged()) {
      trovacigusto.modalLogin.headerLabel('Already user?<br>Sign in to become a fan')
      trovacigusto.modalLogin.show()
      return
    }

    trovacigusto.flagVM.store('catering', self.cateringId()).then(function (response) {
      if (response.success) {
        pNotify('You are now a Fan of ' + response.data.name)
        self.isFavorite(true)
        self.update()
        return
      }
      if (response.status === 'flag.exist') {
        self.update()
      } else
        pNotify('There was a problem!', 'error')
    })
  }

}