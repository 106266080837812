function AddressModule(address) {
    var self = this;

    self.address = new AddressModel(address);
    self.geocoder = new GeocoderModule();
    self.geocodingEnable = ko.observable(true);

    self.loading = ko.observable(false);

    self.listCountries = ko.observableArray([]);
    self.listStates = ko.observableArray([]);
    self.listProvinces = ko.observableArray([]);
    self.listCities = ko.observableArray([]);

    self.selectedCountry = ko.observable();
    self.selectedState = ko.observable();
    self.selectedProvince = ko.observable();
    self.selectedCity = ko.observable();

    self.getCountries = function () {
        self.loading(true);
        return rest('GET', '/api/v2/geo/find?type=countries').then(function (response) {
            if (response.success && response.data.countries)
                self.listCountries(response.data.countries);
            self.loading(false);
            return response

        }).catch(function (response) {
            self.loading(false);
            return response
        });
    };

    self.getStates = function (code) {
        self.loading(true);
        return rest('GET', '/api/v2/geo/find?type=states&id=172').then(function (response) {
            if (response.success && response.data.states)
                self.listStates(response.data.states);
            self.loading(false);
            return response

        }).catch(function (response) {
            self.loading(false);
            return response
        });
    };

    self.getProvinces = function (code) {
        self.loading(true);
        return rest('GET', '/api/v2/geo/find?type=provinces&id=' + code).then(function (response) {
            if (response.success && response.data.provinces)
                self.listProvinces(response.data.provinces);
            self.loading(false);
            return response

        }).catch(function (response) {
            self.loading(false);
            return response
        });
    };

    self.getCities = function (code) {
        self.loading(true);
        return rest('GET', '/api/v2/geo/find?type=cities&id=' + code).then(function (response) {
            if (response.success && response.data.cities)
                self.listCities(response.data.cities);
            self.loading(false);
            return response

        }).catch(function (response) {
            self.loading(false);
            return response
        });
    };

    self.selectedState.subscribe(function (code) {
        if (code) {
            self.address.state_id(code);
            var match = ko.utils.arrayFirst(self.listStates(), function (item) {
                return item.id === code;
            });
            self.address.state_name(match ? match.name : '');
            self.getProvinces(code).then(function (response) {
                if (response.success && self.address.province_id())
                    self.selectedProvince(self.address.province_id());
            });
        }
    });

    self.selectedProvince.subscribe(function (code) {
        if (code) {
            self.address.province_id(code);
            var match = ko.utils.arrayFirst(self.listProvinces(), function (item) {
                return item.id === code;
            });
            self.address.province_name(match ? match.name : '');
            self.getCities(code).then(function (response) {
                if (response.success && self.address.city_id())
                    self.selectedCity(self.address.city_id());
            });
        }

    });

    self.selectedCity.subscribe(function (code) {
        if (code) {
            if (code !== self.address.city_id())
                self.geocodingCallback();
            self.address.city_id(code);
            var match = ko.utils.arrayFirst(self.listCities(), function (item) {
                return item.id === code;
            });
            self.address.city_name(match ? match.name : '');

        }
    });


    self.addressComplete = ko.computed(function () {
        if (!self.address)
            return false;
        return self.address.street() !== "" && self.address.district() !== "" && self.address.postal_code() !== "" && self.address.country_id() && self.address.city_id() && self.address.state_id() && self.address.province_id() && self.address.latitude() && self.address.longitude() && self.address.latitude() > 0 && self.address.longitude() > 0;
    });


    self.initMap = function () {
        self.geocoder.init('gmap', self.address)
    };

    self.save = function () {
        rest(self.address.id() ? 'POST' : 'PUT', '/api/v2/addresses/' + (self.address.id() ? self.address.id() : ''), self.address)
            .then(function (response) {
                if (!response.success) {
                    pNotify("The address has been saved but some required fields are missing", 'warning');
                } else
                    pNotify("The address has been saved successfully", 'success');
            });
    };

    self.geocoder.init('gmap', self.address);

    self.geocodingCallback = function () {
        if (self.geocodingEnable())
            self.geocoder.geocode(function (result) {
                if (result) {
                    self.address.latitude(parseFloat(result.lat));
                    self.address.longitude(parseFloat(result.lon));
                } else {
                    self.address.latitude(null);
                    self.address.longitude(null);
                }
            });
    };

    self.address.street.subscribe(self.geocodingCallback);
    self.address.number.subscribe(self.geocodingCallback);
    self.address.district.subscribe(self.geocodingCallback);
    self.address.postal_code.subscribe(self.geocodingCallback);

    self.getStates(self.address.country_id()).then(function (response) {
        if (response.success && self.address.state_id()) {
            self.selectedState(self.address.state_id());
        }
    });

    return self;
}