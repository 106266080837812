function DishModel(item) {
    //var self = ko.mapping.fromJS(item);
    var self = this;

    self.bundle = {};

    self.id = ko.observable(item ? item.id : null)
    self.catering_id = ko.observable(item ? item.catering_id : null)
    self.name = ko.observable(item ? item.name : null)
    self.price_sale = ko.observable(item ? item.price_sale : 0);
    self.price = ko.observable(item ? item.price : 0);
    self.description = ko.observable(item ? item.description : null);
    self.isNew = ko.observable(item ? item.isNew : false);
    self.isSpecial = ko.observable(item ? item.isSpecial : false);
    self.isAvailable = ko.observable(item ? item.isAvailable : true);
    self.isPublished = ko.observable(item ? item.isPublished : true);
    self.position = ko.observable(item ? item.position : null);
    self.food_category_id = ko.observable(item ? item.food_category_id : null);
    self.discount = ko.observable(item ? item.discount : 0);
    self.unit = ko.observable(item ? item.unit : null);
    self.unit_quantity = ko.observable(item ? item.unit_quantity : null);
    self.isBookable = ko.observable(item ? item.isBookable : true);
    self.amount = ko.observable(item ? item.amount : null);
    self.bookable_amount_min = ko.observable(item ? item.bookable_amount_min : null);
    self.bookable_amount_max = ko.observable(item ? item.bookable_amount_max : null);
    self.bookable_price_min = ko.observable(item ? item.bookable_price_min : null);
    self.serviceTakeAway = ko.observable(item ? item.serviceTakeAway : false);
    self.serviceTable = ko.observable(item ? item.serviceTable : false);
    self.serviceDelivery = ko.observable(item ? item.serviceDelivery : false);
    self.servicePreparedHome = ko.observable(item ? item.servicePreparedHome : false);
    self.serviceSentHome = ko.observable(item ? item.serviceSentHome : false);
    self.isPriceHidden = ko.observable(item ? item.isPriceHidden : false);
    self.course = ko.observable(item ? item.course : null);

    self.discount = ko.computed(function () {
        if (Number(self.price_sale()) === 0 && Number(self.price()) === 0)
            return 0;

        var discount = Math.round(100 - (Number(self.price_sale()) / Number(self.price()) * 100));
        if (discount >= 0 && discount <= 100)
            return discount;
        else {
            self.price(self.price_sale());
            return 0;
        }
    });


    if (item && item.bundle) {
        self.bundle.imageProfile = ko.observable(item.bundle.imageProfile ? item.bundle.imageProfile : null);
        self.bundle.course = ko.observable(item.bundle.course ? item.bundle.course : null);
    }

    self.printPrice = function () {
        if (self.isPriceHidden()) {
            return 'Price upon request'
        }
        if (self.price_sale() > 0) {
            if (self.discount() > 0) {

                return self.price_sale() + "£" + " (" + self.price() + "£ -" + self.discount() + "%)";
            }
            if (item.discount === 0) {
                return self.price_sale() + "£";

            }
        }
        if (self.price_sale() === 0) {
            return 'Free';
        }
        return self.price_sale() + '£';

    }

    return self;

}
